import React, { Component } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import Carelabel from '../svg/carelabel.svg';

import Tee from '../svg/garments/tee.svg';
import Jumper from '../svg/garments/jumper.svg';
import Hoodie from '../svg/garments/hoodie.svg';

function NewlineText(props) {
  const text = props.text;
  return text.split('\n').map((str) => <p>{str}</p>);
}

function SizePicker(props) {
  if (props === 'jumpers') {
    return <Jumper width="100%" height="100%" />;
  }
  if (props === 't-shirt') {
    return <Tee width="100%" height="100%" />;
  }
  if (props === 'hoodie') {
    return <Hoodie width="100%" height="100%" />;
  } else return <p></p>;
}
class CardComponent extends Component {
  state = { isActive: true };

  toggleVisibility = () =>
    this.setState((prevState) => ({ isActive: !this.state.isActive }));

  render = () => (
    <div className="accordion-container">
      <div
        className={`accordion-content ${
          this.state.isActive ? 'is-active' : 'is-inactive'
        }`}
      >
        <Card className="">
          <div className="py-6 cursor-default md:py-12 text-primary">
            <div className="px-6">
              <div className="flex flex-col items-stretch justify-start -mx-0 md:flex-row">
                <div className="w-full">
                  <div className="h-full">
                    <div className="px-1 sm:px-3 md:px-5">
                      <div className="py-1 sm:py-2 md:py-3">
                        <h4 className="pb-2 font-serif text-xl font-extrabold">
                          product info
                        </h4>
                        <div className="my-2">
                          <div className="my-2">
                            <p className="text-xs leading-relaxed text-primary">
                              ID
                            </p>
                            <span className="p-1 text-xs leading-relaxed uppercase border text-primary border-primary">
                              #{this.props.productRef}
                            </span>
                          </div>
                          <p className="pt-4 text-xs leading-relaxed text-primary opacity-80">
                            <NewlineText text={this.props.productInfo} />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="pb-8">
                      <div className="px-1 sm:px-3 md:px-5">
                        <div className="py-1 sm:py-2 md:py-3">
                          <h4 className="pb-2 font-serif text-xl font-extrabold">
                            garment care
                          </h4>
                          <div className="my-2">
                            <p className="text-xs leading-relaxed text-primary opacity-80">
                              <NewlineText text={this.props.productCare} />
                            </p>
                            <div className="mt-8">
                              <Carelabel />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <section className="flex">
                      <div className="w-full">
                        <div className="h-full border-solid sm:border-l-1">
                          <div className="px-1 sm:px-3 md:px-5">
                            <div className="py-1 sm:py-2 md:py-3">
                              <h4 className="pb-2 font-serif text-xl font-extrabold">
                                delivery
                              </h4>
                              <div className="my-2">
                                <div className="my-2">
                                  <p className="text-xs leading-relaxed text-primary opacity-80">
                                    2 — 7 days UK <br /> 10 — 17~ days
                                    International
                                  </p>
                                </div>
                                <div className="inline-block border-b-1 border-darker-grey ">
                                  <br />
                                  <Link
                                    className="text-xs leading-relaxed underline hover:text-primary text-primary"
                                    to="/stuff/#deliverey"
                                  >
                                    view shipping times &amp; costs
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-full">
                        <div className="h-full border-solid sm:border-l-1">
                          <div className="px-1 sm:px-3 md:px-5">
                            <div className="py-1 sm:py-2 md:py-3">
                              <h4 className="pb-2 font-serif text-xl font-extrabold">
                                impact
                              </h4>
                              <div className="my-2">
                                <div className="my-2">
                                  <p className="text-xs leading-relaxed text-primary opacity-80">
                                    1 order placed = 1 tree planted 🌳
                                    <br />
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>

                <div className="w-full">
                  <div className="">
                    <div className="px-1 sm:px-3 md:px-5">
                      <div className="py-1 sm:py-2 md:py-3">
                        <h4 className="pb-2 font-serif text-xl font-extrabold">
                          sizing
                        </h4>
                        <p className="text-xs leading-relaxed text-primary opacity-80">
                          All our clothes are unisex, a general rule to keep is;
                          if you are a females MEDIUM, get a SMALL - a size
                          down. Males get the size you would normally.
                        </p>

                        {/* <Link
                          className="text-xs leading-relaxed underline hover:text-primary text-primary"
                          to="/stuff/#sizing"
                        >
                          view sizing
                        </Link> */}
                        <br />
                        <p className="text-xs text-primary opacity-80">
                          <NewlineText text={this.props.wearing} />
                        </p>
                        <div className="my-10">
                          {SizePicker(this.props.productCategory)}
                        </div>
                        <div className="my-2"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
      {/* <button
        onClick={this.toggleVisibility}
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
          padding: '2rem',
          borderTop: '1px solid black',
          background: 'none',
          marginTop: '-1px',
        }}
      >
        <p className="text-base font-extrabold">
          {!this.state.isActive ? '+' : ' -'}
        </p>
      </button> */}
    </div>
  );
}

export default CardComponent;

CardComponent.propTypes = {
  title: PropTypes.string.isRequired,
};
