import React, { useEffect, useState } from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useCart } from 'react-use-cart';
import Seo from '../components/SEO';
import Detail from '../components/Detail';
import useSound from 'use-sound';
import Switch from '../sounds/switch.mp3';

import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import ProductGrid from '../components/ProductGrid';

async function postData(url = '', data = {}) {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return await response.json();
}

function ProductPage({ data: { product, products }, location }) {
  const variants = [];
  const [variantQuantity] = useState(1);
  const { addItem } = useCart();
  product.stockSystems.map((item) => variants.push(item.size));
  const [activeVariant, setActiveVariant] = useState(variants[0]);

  //sounds
  const [play] = useSound(Switch, { volume: 0.5 });

  //stock
  const [StockStatus, setStockStatus] = useState(0);
  const [fetched, setfetched] = useState(false);

  const fetchStockData = () => {
    (async () => {
      const { data } = await postData(
        'https://api-eu-central-1.graphcms.com/v2/ckdrrcioy0k8401z1bfusegqy/master',
        {
          query: ` query OneProductQuery($productID:ID!,$StockSize:ProductSize,$size:String!) 
        {
          product(where: {id: $productID}) {
            name
            orderItems (where: {size: $size}){
              size
              quantity
            }
            stockSystems(where: {size: $StockSize}) {
              size
              stockCount
            }
         
          }
        }`,
          variables: {
            productID: product.remoteId,
            size: activeVariant,
            StockSize: activeVariant,
          },
        }
      );
      setfetched(true);
      var AllStock = data.product.stockSystems[0].stockCount;
      var totoalsold = 0;
      data.product.orderItems.map((SoldOut) => {
        return (totoalsold += SoldOut.quantity);
      });
      setStockStatus(AllStock - totoalsold);
    })();
  };
  useEffect(() => {
    fetchStockData();
  }, [fetched, activeVariant]);

  const newInLabel = (
    <div className="flex flex-col items-end py-4 text-xs text-red">
      <svg
        width="8"
        height="8"
        viewBox="0 0 8 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="animate-pulse"
      >
        <rect width="8" height="8" fill="currentColor" />
      </svg>
      <p className="my-1 text-xs">NEW IN</p>
    </div>
  );

  const organicLabel = (
    <div className="py-4 text-secondary">
      <svg
        width="16"
        height="13"
        viewBox="0 0 16 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.18264 12.2782C6.48943 12.4383 3.19141 8.5869 3.19141 4.8154C3.8817 8.02483 7.33311 11.235 7.9467 13C10.248 3.93235 3.72864 1.52572 0.737073 0C-1.79397 7.46383 2.73123 10.593 6.18264 12.2782ZM9.71041 12.3588C11.9347 12.5995 15.156 12.3588 16 7.70421C14.0062 7.62361 10.0172 6.82116 8.63664 12.1979C9.48032 11.4754 12.1648 10.914 13.3919 9.38935C12.3188 11.3951 9.55736 12.3588 9.71041 12.3588Z"
          fill="currentColor"
        />
      </svg>
      <p className="text-sm">organic</p>
    </div>
  );

  if (!product) return null;

  function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
  }

  const productPictureStyle = {
    minHeight: '100%',
    minWidth: '100%',
    alignItems: 'center',
    marginBottom: '-1px',
  };
  const productPictureStyleSecond = {
    alignItems: 'center',
    minWidth: '100%',
  };

  function StoreData() {
    typeof window !== 'undefined' &&
      window.localStorage.setItem(
        'itemName',
        `${product.name} Size: ${activeVariant}`
      );
  }

  function AddToCart() {
    if (typeof window !== 'undefined') {
      if (window.fbq != null) {
        window.fbq('track', 'AddToCart', {
          content_ids: product.remoteId,
          content_type: 'product',
          value: product.price,
          currency: 'GBP',
        });
      }
    }
  }
  function NewlineText(props) {
    const text = props.text;
    return text.split('\n').map((str) => <p>{str}</p>);
  }
  return (
    <React.Fragment>
      <Seo
        pageTitle={product.name}
        productId={product.id}
        productPrice={product.price}
        category={product.category.remoteId}
        remoteId={product.remoteId}
        productDescription={product.description.markdown}
        url={location.href}
        image={product.images[0].url}
      />

      <div className="mt-16 lg:flex image-fix-counter md:mt-20 lg:mt-12">
        <div className="flex flex-col justify-center overflow-hidden border md:mb-0 lg:w-1/2 lg:-mr-px border-primary image-fix">
          <div className="flex flex-row overflow-x-scroll overflow-y-hidden wrapping sm:flex-col md:overflow-y-scroll scrollbar">
            {product.images
              ? product.images.slice(1).map((image) => (
                  <Zoom wrapStyle={productPictureStyleSecond}>
                    <div className="relative w-full border-l md:border-b border-primary md:border-l-0 slide-left">
                      <GatsbyImage
                        loading="eager"
                        alt={product.name}
                        image={image.localFile.childImageSharp.gatsbyImageData}
                      />
                    </div>
                  </Zoom>
                ))
              : null}
            <Zoom wrapStyle={productPictureStyle}>
              <div className="relative w-full p-6">
                <GatsbyImage
                  image={getImage(product.images[0].localFile)}
                  alt={product.name}
                  title={product.name}
                />
              </div>
            </Zoom>
          </div>
        </div>
        <div className="flex flex-col justify-between -mt-px border lg:w-1/2 border-primary lg:mt-0">
          <section className="flex flex-col justify-between h-full p-8 md:p-12">
            <div className="">
              <div className="flex flex-row-reverse justify-between">
                {product.newIn ? (
                  <div className="text-black">{newInLabel} </div>
                ) : (
                  <p className="text-xs text-white ">—</p>
                )}

                {product.organic ? (
                  organicLabel
                ) : (
                  <p className="text-xs text-white ">—</p>
                )}
              </div>
              <h1
                id="productName"
                className="mb-4 text-4xl font-bold text-primary"
              >
                {product.name}
              </h1>

              {product.description && (
                <div className="mb-6">
                  <p className="text-sm leading-loose text-primary opacity-80">
                    <NewlineText text={product.description.markdown} />
                  </p>
                </div>
              )}
            </div>

            <div className="mb-6">
              <p className="text-2xl text-primary">
                {product.inStock ? (
                  <p id="productPrice">£{product.price}</p>
                ) : (
                  <div>
                    <p> out of stock</p>
                  </div>
                )}
              </p>
            </div>
          </section>
          <section className="">
            <div className="md:flex md:flex-wrap">
              <div className="md:w-full">
                <div className="relative">
                  <select
                    id="style"
                    value={activeVariant}
                    onChange={({ target: { value } }) =>
                      setActiveVariant(value)
                    }
                    className="block w-full h-16 px-4 py-3 pr-8 border-t rounded-none appearance-none border-primary focus:border-primary focus:outline-none focus:bg-white text-primary focus:text-primary"
                  >
                    {variants.map((variant, index) => (
                      <option key={index} value={variant}>
                        {variant}
                      </option>
                    ))}
                  </select>
                  <div className="absolute inset-y-0 right-0 flex items-center px-4 pointer-events-none text-primary">
                    <svg
                      className="w-4 h-4 fill-current"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div role="button" onClick={play} onKeyDown={play} tabIndex={0}>
              {StockStatus <= 0 ? (
                <Link
                  className="block w-full border-t tertiary-btn border-primary"
                  to="/restock"
                  onClick={StoreData}
                  // onClick={handleSubmit(product.itemName)}
                >
                  holla when this size is back
                </Link>
              ) : (
                <div className="CTA">
                  <div className="h-px bg-primary"></div>
                  <button
                    className="block w-full primary-btn"
                    onClick={() => {
                      AddToCart();
                      addItem({
                        id: product.remoteId,
                        price: parseInt(product.price),
                        name: product.name,
                        description: product.description.markdown,
                        quantity: variantQuantity,
                        image: product.images[0],
                        productID: product.remoteId,
                        size: activeVariant,
                      });
                    }}
                    //disabled={!activeVariant}
                  >
                    add to bag
                  </button>
                </div>
              )}
            </div>
          </section>
        </div>
      </div>
      <div className="mr-0 -mt-px border border-primary md:mr-px">
        <Detail
          title="more details"
          productInfo={product.productInfo.markdown}
          productCare={product.garmentCare.markdown}
          productCategory={product.category.name}
          wearing={product.wearing?.markdown ?? ''}
          productRef={product.remoteId.slice(-8)}
        />
      </div>
      <section>
        <p className="py-4 pl-4 mt-24 font-serif text-2xl font-bold md:pl-0 text-primary">
          you'll also like
        </p>

        {/* <ProductGrid products={products.nodes.slice(myNumber, myNumber + 6)} /> */}
        <ProductGrid
          products={products.nodes.slice(
            getRandomArbitrary(1, products.nodes.length)
          )}
        />
      </section>
    </React.Fragment>
  );
}
export const pageQuery = graphql`
  query ProductQuery($id: ID!) {
    product: graphCmsProduct(remoteId: { eq: $id }) {
      id
      remoteId
      name
      organic
      inStock
      newIn
      price
      stockSystems {
        size
      }
      description {
        html
        markdown
      }
      productInfo {
        markdown
        html
      }
      garmentCare {
        markdown
        html
      }
      wearing {
        markdown
        html
      }
      category {
        name
        remoteId
      }
      images {
        url
        localFile {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: [AUTO]
            )
          }
        }
      }
    }
    products: allGraphCmsProduct {
      nodes {
        id
        name
        organic
        inStock
        newIn
        price
        images {
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO]
              )
            }
          }
        }

        remoteId
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

export default ProductPage;
