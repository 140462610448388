import React from 'react';

import Product from './Product';

function ProductGrid({ products }) {
  if (!products) return null;
  return (
    <div className="flex flex-wrap -mx-6 products">

      {products.map((p) => (

        <Product
          key={p.remoteId}
          remoteId={p.remoteId}
          name={p.name}
          price={p.price}
          newIn={p.newIn}
          organic={p.organic}
          image={p.images}
          {...p}
        />
      ))}

    </div>

  );

}

export default ProductGrid;
