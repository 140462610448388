import React from 'react';
import LocaleLink from './LocaleLink';
import useSound from 'use-sound';
import Click from '../sounds/click.mp3';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

function Product({ remoteId, inStock, name, newIn, organic, image, price }) {
  const [mainImage] = image;
  const [click] = useSound(Click, { volume: 0.1 });

  const newInLabel = (
    <div className="flex flex-row items-baseline px-6 py-1 text-xs text-red">
      <svg
        width="8"
        height="8"
        viewBox="0 0 8 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="animate-pulse"
      >
        <rect width="8" height="8" fill="currentColor" />
      </svg>
      <p className="mx-2 text-xs">NEW IN</p>
    </div>
  );

  return (
    <article className="w-full md:w-1/2 lg:w-1/3 product">
      <LocaleLink
        to={`/products/${name.replace(/\s+/g, '-')}`}
        onClick={click}
        className="box-content flex w-full h-full no-underline group"
      >
        <div className="relative w-full px-3 py-8 overflow-hidden cursor-pointer md:px-6">
          <div className="absolute z-10"> {newIn ? newInLabel : null}</div>
          <GatsbyImage
            image={getImage(mainImage.localFile)}
            alt={name}
            title={name}
          />
          <div className="pt-3 text-center md:pt-6 ">
            <div className="flex flex-col items-center">
              {organic ? (
                <p className="text-xs text-secondary">organic</p>
              ) : (
                <p className="text-xs text-white ">—</p>
              )}
            </div>
            <p className="font-serif text-lg font-bold text-primary">{name}</p>
            <p className="text-sm text-primary">
              {inStock ? `£${price}` : <>Coming Soon</>}
            </p>
          </div>
        </div>
      </LocaleLink>
    </article>
  );
}

export default Product;
